<template>
    <div class="registration">
        <div v-if="$root.appType('cash') || $root.appType('nordwind') || $root.appType('southwind')">
            <div v-if="activeForm !== 'WaitingStatus'">
                <div
                    class="registration__header d-flex align-center justify-space-between"
                    >
                    <div>
                        <h3 class="form-title text-left">Регистрация клиента</h3>
                        <p class="main-text neris mt-2">
                            Кредит наличными на любые цели*
                        </p>
                    </div>

                    <div v-if="!$root.isSizeAndDown('sm')" class="registration__summ ml-3">
                        {{ anketa.initialPayment.value.toLocaleString("ru-RU") }}
                        <span>&#8381;</span>
                    </div>
                </div>
                <div v-if="$root.isSizeAndDown('sm')" class="registration__summ">
                    {{ anketa.initialPayment.value.toLocaleString("ru-RU") }}
                    <span>&#8381;</span>
                </div>
            </div>
            <div class="registration__body mt-5">
                <component
                    :waitingData="waitingData"
                    :is="forms[activeForm]"
                    :activeForm="activeForm"
                    @changeTemplate="changeTemplate"
                    @nextStep="nextStep"
                />
            </div>
            <PanelList
                v-if="activeForm !== 'WaitingStatus'"
            />
        </div>

        <div v-if="$root.appType('vtb')">
            <div v-if="activeForm !== 'WaitingStatus'">
                <div
                    class="registration__header d-flex align-center justify-space-between"
                    >
                    <div>
                        <h3 class="vtb_form-title text-left">Регистрация клиента</h3>
                        <p class="vtb_form-subtitle mt-2">
                            Кредит наличными на любые цели*
                        </p>
                    </div>

                    <div v-if="!$root.isSizeAndDown('sm')" class="vtb_registration__summ ml-3">
                        {{ anketa.initialPayment.value.toLocaleString("ru-RU") }}
                        <span>&#8381;</span>
                    </div>
                </div>
                <div v-if="$root.isSizeAndDown('sm')" class="vtb_registration__summ vtb_registration__sum_mobile mt-8 text-left">
                    {{ anketa.initialPayment.value.toLocaleString("ru-RU") }}
                    <span>&#8381;</span>
                </div>
            </div>
            <div class="registration__body mt-5">
                <component
                    :waitingData="waitingData"
                    :is="forms[activeForm]"
                    :activeForm="activeForm"
                    @changeTemplate="changeTemplate"
                    @nextStep="nextStep"
                />
            </div>
            <PanelList
                v-if="activeForm !== 'WaitingStatus'"
            />
            <div v-if="$root.appType('vtb') && activeForm === 'MainRegistration'" class="vtb_registration_footer">
                <p class="vtb_registration_footer_text">Возможно, нам потребуется дополнительная информация.</p>
            </div>
        </div>

        <div v-if="$root.appType('travelata')">
            <div v-if="activeForm !== 'WaitingStatus'">
                <div
                    class="registration__header d-flex align-center justify-space-between"
                    >
                    <div>
                        <h3 class="travelata_form-title text-left">Регистрация клиента</h3>
                        <p class="travelata_form-subtitle mt-2">
                            Кредит* наличными на любые цели
                        </p>
                    </div>

                    <div v-if="!$root.isSizeAndDown('sm')" class="travelata_registration__summ ml-3">
                        {{ anketa.initialPayment.value.toLocaleString("ru-RU") }}
                        <span :class="$root.appType('travelata') ? 'travelata_ruble_icon_strong' : ''">&#8381;</span>
                    </div>
                </div>
                <div v-if="$root.isSizeAndDown('sm')" class="travelata_registration__summ travelata_registration__summ_mobile mt-8 text-left">
                    {{ anketa.initialPayment.value.toLocaleString("ru-RU") }}
                    <span>&#8381;</span>
                </div>
            </div>
            <div class="registration__body mt-5">
                <component
                    :waitingData="waitingData"
                    :is="forms[activeForm]"
                    :activeForm="activeForm"
                    @changeTemplate="changeTemplate"
                    @nextStep="nextStep"
                />
            </div>
            <PanelList
                v-if="activeForm !== 'WaitingStatus'"
            />
            <div v-if="$root.appType('vtb') && activeForm === 'MainRegistration'" class="vtb_registration_footer">
                <p class="vtb_registration_footer_text">Возможно, нам потребуется дополнительная информация.</p>
            </div>
        </div>
    </div>
</template>

<script>
import MainRegistration from "@/components/modal/registration/forms/MainRegistration";
import AdditionalInfo from "@/components/modal/registration/forms/AdditionalInfo";
import WaitingStatus from "@/components/modal/registration/forms/WaitingStatus";
import PanelList from "@/components/modal/registration/PanelList.vue";

export default {
    name: "registration",
    components: {
        PanelList
    },
    data: () => ({
        activeForm: "MainRegistration",
        waitingData: {},

        forms: {
            MainRegistration,
            WaitingStatus,
            AdditionalInfo,
        },
    }),
    computed: {
        anketa() {
            return this.$root.anketa;
        },
    },

    methods: {
        nextStep(val, data) {
            this.activeForm = val;

            this.waitingData = data;
        },

        changeTemplate(next) {
            const data = {
                success: true,
                prev: "registration",
                next: next,
            };

            this.$emit("changeTemplate", data);
        },

        // appType(type){
        //     if(type === this.$root.state.type()){
        //         return true
        //     }else {
        //         return false
        //     }
        // },

    },

};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vtb-variables.scss";
@import "@/assets/scss/_travelata-variables.scss";


.vtb {
    &_form {
        &-title {
            @include vtb-form-title;
            color: #2F3441;
        }

        &-subtitle {
            @include vtb-form-subtitle ;
            color: #2F3441;
        }
    }

    &_registration__summ {
        @include vtb-form-sum;
        color: #2F3441;


    }

    //&_panel-list {
    //    display: grid;
    //    grid-template-columns: 45% 45%;
    //    justify-content: space-between;
    //    padding-left: 16px !important;
    //    list-style: none;
    //
    //    &_item {
    //        font-family: "Roboto";
    //        font-weight: 300;
    //        font-size: 12px;
    //        line-height: 1.3em !important;
    //        position: relative;
    //        padding-left: 36px;
    //        margin-top: 18px;
    //
    //        &:before {
    //            content: "";
    //            position: absolute;
    //            width: 22px;
    //            height: 17px;
    //            left: 0;
    //            top: 1px;
    //
    //            // background-image: url(../img/file-wordlist-item.svg);
    //            background-image: url(../../../assets/img/vtb/vtb_file-word.svg);
    //            background-repeat: no-repeat;
    //        }
    //
    //        a {
    //            text-decoration: none;
    //            color: #656565;
    //        }
    //    }
    //}

    //&_registration_bottom_text {
    //    @include small-text;
    //    font-size: 13px !important;
    //    line-height: 16px !important;
    //    letter-spacing: -0.5px !important;
    //    text-decoration: none;
    //
    //}

    &_registration_footer {
        width: 120%;
        height: 64px;
        background-color: #E7E7E7;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 50px -32px -32px;

        &_text {
            @include vtb-form-text;
            font-weight: 300;
            font-size: 13px;
            color: #2F3441;
            padding-top: 10px;
        }
    }
    //
    //&_link {
    //    color: #1976D2;
    //}

}
.travelata {
    &_form {
        &-title {
            @include tr-text(24px, 800);
            font-family: 'OpenSansEB';
            color: #333;
        }

        &-subtitle {
            @include tr-text(18px);
            font-family: 'OpenSansR';
            color: #333;
        }
    }

    &_registration__summ {
        @include tr-text(40px, 800);
        font-family: 'OpenSansEB';
        color: #333;

        @media(max-width: 1264px) {
            font-size: 30px !important;
        }

        &_mobile {
            & span {
                font-family: Roboto;
                font-size: 29px !important;
            }
        }
    }
    //&_expantion-panel {
    //    &_title {
    //        @include tr-text();
    //        font-family: 'OpenSansR' !important;
    //        color: #333 !important;
    //    }
    //    &_subtitle {
    //        @include tr-text(12px, 400, 16px);
    //        font-family: 'OpenSansR';
    //        color: #707070;
    //    }
    //}
    //&_panel-list {
    //    display: grid;
    //    grid-template-columns: 45% 45%;
    //    justify-content: space-between;
    //    //padding-left: 16px !important;
    //    padding-left: 0;
    //    list-style: none;
    //
    //    &_item {
    //        @include tr-text(12px, 400, 16px);
    //        font-family: 'OpenSansR';
    //        color: #707070;
    //        position: relative;
    //        padding-left: 36px;
    //        margin-top: 18px;
    //        text-align: left;
    //
    //        &:before {
    //            content: "";
    //            position: absolute;
    //            width: 22px;
    //            height: 17px;
    //            left: 0;
    //            top: 1px;
    //
    //            // background-image: url(../img/file-wordlist-item.svg);
    //            background-image: url(../../../assets/img/travelata/travelata_file-word.svg);
    //            background-repeat: no-repeat;
    //        }
    //
    //        a {
    //            text-decoration: none;
    //            color: #656565;
    //        }
    //    }
    //}

    &_registration_bottom_text {
        @include tr-text(12px, 400, 16px);
        font-family: 'OpenSansR';
        color: #707070;
        text-decoration: none;

    }

    &_registration_footer {
        width: 120%;
        height: 64px;
        background-color: #E7E7E7;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 50px -32px -32px;

        &_text {
            @include vtb-form-text;
            font-weight: 300;
            font-size: 13px;
            color: #2F3441;
            padding-top: 10px;
        }
    }

    &_link {
        color: #059BCE;
    }

    &_ruble_icon_strong {
        font-family: "Roboto";
        font-size: 39px;

        @media(max-width: 1264px) {
            font-size: 29px !important;
        }
    }

}
</style>
