<template>
  <!--block4-->
    <div
        class="block-4"
        id="calculated"
        >
        <div class="container-width">
            <BlockHTML4Cash v-if="$root.appType('cash')"/>
            <BlockHTML4Nordwind v-if="$root.appType('nordwind') || $root.appType('southwind')"/>
            <BlockHTML4Vtb v-if="$root.appType('vtb')"/>
            <BlockHTML4Travelata v-if="$root.appType('travelata')"/>
        </div>
<!--        <BlockHTML4Travelata v-if="$root.appType('travelata')"/>-->
    </div>
</template>

<script>
import BlockHTML4Cash from "@/components/html/blocksParts/BlockHTML4Cash.vue";
import BlockHTML4Vtb from "@/components/html/blocksParts/BlockHTML4Vtb.vue";
import BlockHTML4Nordwind from "@/components/html/blocksParts/BlockHTML4Nordwind.vue";
import BlockHTML4Travelata from "@/components/html/blocksParts/BlockHTML4Travelata.vue";

export default {
    name: "BlockHTML4",
    components: {
        BlockHTML4Cash,
        BlockHTML4Vtb,
        BlockHTML4Nordwind,
        BlockHTML4Travelata
    },
  computed: {
    getUrlParams(){
      const urlLocation = location.search.split('&')
      return urlLocation[1]
    }
  },
  methods: {
    urlParams(param){
      const urlLocation = location.search.split('&')
      return urlLocation.includes(param)
    },

    appType(type){
      if(type === this.$root.state.type()){
        return true
      }else {
        return false
      }
    }
  // openFile() {
  // window.open(require('../../assets/docs/index.pdf'), '_blank')
  // }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vtb-variables.scss";
@import "@/assets/scss/_travelata-variables.scss";

.button_center {
  margin: 0 auto;

}

//.nordwind-discount {
//  background-color: #fff;
//  border-radius: 24px;
//  box-shadow: 0px 3px 5px rgba(95, 99, 104, 0.05),
//  0px 10px 10px rgba(95, 99, 104, 0.05),
//  0px 1px 18px rgba(95, 99, 104, 0.05);
//  margin-bottom: 50px;
//
//  &_title {
//    padding: 0 20px;
//    @media (max-width: 500px) {
//      font-size: 30px;
//      padding: 20px 20px 0;
//    }
//  }
//}
//
//.nordwind-discount_info_item {
//  max-width: 336px;
//  width: 100%;
//  padding: 32px;
//  min-height: 272px;
//  margin: 0 16px 0 16px;
//  border-radius: 16px;
//  position: relative;
//
//  .discount-info-block {
//    height: 100px;
//
//    img {
//      max-height: 100%;
//    }
//  }
//
//  .container-info-limit {
//    display: flex;
//    flex-direction: row;
//    justify-content: end;
//    align-items: baseline;
//
//    .container-info-limit_description {
//      width: 50%;
//      font-family: Roboto;
//      font-style: normal;
//      font-weight: 300;
//      font-size: 18px;
//      line-height: 1.3em;
//      display: flex;
//      align-items: center;
//      color: #333333;
//
//      margin-bottom: 18px;
//    }
//
//      .container-info-limit_price {
//        border-radius: 8px;
//        height: 40px;
//        font-family: RobotoBold;
//        display: flex;
//        flex-direction: row;
//        justify-content: flex-end;
//        align-items: center;
//        width: 50%;
//        margin: 0;
//
//        p {
//          font-family: NerisBlack;
//          font-style: normal;
//          font-weight: 900;
//          font-size: 32px;
//          line-height: 1.4em;
//          display: flex;
//          align-items: center;
//          text-align: right;
//          color: #0a0a0d;
//          margin-bottom: 0 !important;
//        }
//
//        span {
//          font-family: NerisBlack;
//          font-style: normal;
//          font-weight: 900;
//          font-size: 32px;
//          line-height: 1.4em;
//          display: flex;
//          align-items: center;
//          text-align: right;
//          color: #0a0a0d;
//          padding: 0 0 2px 5px;
//        }
//      }
//    }
//  }
//
//  .nordwind-discount {
//    background-color: #fff;
//    border-radius: 24px;
//    box-shadow: 0px 3px 5px rgba(95, 99, 104, 0.05),
//    0px 10px 10px rgba(95, 99, 104, 0.05),
//    0px 1px 18px rgba(95, 99, 104, 0.05);
//    margin-bottom: 50px;
//
//  &_title {
//    padding: 0 20px;
//    @media (max-width: 500px) {
//      font-size: 30px;
//      padding: 20px 20px 0;
//    }
//  }
//}

//.vtb {
//  max-width: 1100px;
//  width: 100%;
//  display: flex;
//  flex-direction: column;
//  align-items: center;
//
//  &-discount {
//    border: 1px solid #F3F7FA;
//    border-radius: 8px;
//    background-color: #fff;
//    border-radius: 24px;
//    box-shadow: 0px 3px 5px rgba(95, 99, 104, 0.05),
//    0px 10px 10px rgba(95, 99, 104, 0.05),
//    0px 1px 18px rgba(95, 99, 104, 0.05);
//    padding: 34px 20px 0;
//    margin-bottom: 60px;
//    width: 100%;
//
//    &-info {
//      display: flex;
//      justify-content: space-between;
//      gap: 40px;
//    }
//
//    &_title {
//      @include big-text;
//      color: #2F3441;
//      margin: 0;
//
//      @media (max-width: 475px){
//        @include semibold-text;
//      }
//      @media (max-width: 416px){
//        line-height: 36px;
//      }
//    }
//
//    &-info-text {
//      @include medium-text;
//      color: #2F3441;
//
//
//    }
//
//    &_info_item {
//      max-width: 336px;
//      width: 100%;
//      // padding: 32px;
//      // min-height: 272px;
//      // margin: 0 16px 0 16px;
//      border-radius: 16px;
//      position: relative;
//      display: flex;
//      flex-direction: column;
//      align-items: center;
//
//
//      .discount-info-block {
//        height: 100px;
//        margin-bottom: 30px;
//
//        img {
//          max-height: 100%;
//        }
//      }
//
//      .container-info-limit {
//        display: flex;
//        flex-direction: row;
//        justify-content: end;
//        align-items: baseline;
//
//        .container-info-limit_description {
//          width: 50%;
//          font-family: Roboto;
//          font-style: normal;
//          font-weight: 300;
//          font-size: 18px;
//          line-height: 1.3em;
//          display: flex;
//          align-items: center;
//          color: #333333;
//
//          margin-bottom: 18px;
//        }
//
//          .container-info-limit_price {
//            border-radius: 8px;
//            height: 40px;
//            font-family: RobotoBold;
//            display: flex;
//            flex-direction: row;
//            justify-content: flex-end;
//            align-items: center;
//            width: 50%;
//            margin: 0;
//          }
//        }
//    }
//  }
//
//  &_second-block {
//    margin-top: 60px;
//
//    &_title {
//      @include big-text;
//      color: #2F3441;
//      margin-bottom: 50px;
//
//      @media (max-width: 475px){
//        @include semibold-text;
//      }
//
//    }
//
//    &_info {
//      display: flex;
//      justify-content: space-between;
//      @media (max-width: 900px) {
//        flex-direction: column;
//        align-items: center;
//      }
//      &_item {
//        max-width: 366px;
//        width: 100%;
//        height: 260px;
//        border: 1px solid #D4D7DF;
//        border-radius: 9px;
//        padding: 30px 20px;
//
//        @media (max-width: 900px) {
//          max-width: 500px;
//        }
//
//        &_title {
//          @include semibold-text;
//          color: #0A2896;
//          width: 60%;
//          line-height: 25px;
//          @media (max-width: 550px) {
//            font-size: 28px;
//            line-height: 24px;
//          }
//        }
//
//        &_text {
//          @include medium-text;
//          color: #606981;
//          margin-top: 20px;
//
//          & > a {
//            color: #0A2896 !important;
//          }
//        }
//
//      }
//    }
//  }
//
//  &_second_flex {
//    display: flex;
//    justify-content: space-between;
//    align-items: center;
//  }
//
//  &_link {
//    @include medium-text-thin;
//    color: #606981;
//    margin-top: 30px;
//  }
//}

//.first_child{
//  max-width: 180px;
//  width: 100%;
//
//  @media (max-width: 1124px) {
//    max-width: unset;
//  }
//}
//.last_child{
//  max-width: 165px;
//  width: 100%;
//
//  @media (max-width: 1124px) {
//    max-width: unset;
//  }
//  @media (max-width: 415px) {
//    max-width: 240px;
//  }
//}


//.travelata_block4 {
//    margin-bottom: 131px;
//    @media (max-width: 1000px) {
//        margin-top: 30px;
//    }
//
//    &_container {
//        background-color: #fff;
//        border-radius: 4px;
//        padding: 60px 20px;
//        margin-bottom: 60px;
//
//        @media (max-width: 350px) {
//            padding: 30px 20px;
//        }
//    }
//    &_title {
//        @include tr-text(44px, 800, 48px);
//        font-family: 'OpenSansEB';
//        color: #333;
//        text-align: center;
//        margin-bottom: 46px;
//
//        @media (max-width: 700px) {
//            font-size: 30px !important;
//        }
//        @media (max-width: 450px) {
//            font-size: 24px !important;
//        }
//    }
//    &_list {
//        display: flex;
//        justify-content: center;
//        gap: 20px;
//        flex-wrap: wrap;
//        //@media (max-width: 500px) {
//        //
//        //}
//
//        &_item {
//            display: flex;
//            flex-direction: column;
//            align-items: center;
//            max-width: 320px;
//            width: 100%;
//
//            &_img {
//                margin-bottom: 18px;
//            }
//
//            &_text {
//                @include tr-text(18px, 400, 28px);
//                font-family: 'OpenSansR';
//                color: #333;
//
//                @media (max-width: 500px) {
//                    font-size: 18px !important;
//                }
//            }
//        }
//    }
//
//    &_buttons {
//        display: flex;
//        flex-direction: column;
//        align-items: center;
//    }
//
//    &_button {
//        max-width: 352px;
//        width: 100%;
//        height: 64px !important;
//        background-color: #059BCE !important;
//        border-radius: 4px;
//        color: #fff;
//        @include tr-text(20px, 600, 30px);
//        font-family: 'OpenSansSB';
//        text-transform: unset;
//        margin-bottom: 24px;
//
//    }
//
//    &_link {
//        @include tr-text(18px);
//        color: #A7A7A7;
//        text-decoration: underline;
//        font-family: 'OpenSansR';
//    }
//}

</style>
