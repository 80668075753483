
<template>
    <div >
        <v-expansion-panels
            :accordion="true"
            :flat="true"
            :class="$root.state.type()"
            class="registration__panel"
            >
            <v-expansion-panel >
                <v-expansion-panel-header>
                    <p
                        class="registration__panel_text">
                        Нажимая кнопку «Далее», вы принимаете условия настоящего
                        <a class="panel-list_link" >Соглашения</a> на обработку персональных данных.
                    </p>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="registration__panel_container">
                    <h5
                        class="registration__panel_title text-left"
                        >Ознакомьтесь с условиями предоставления сервиса
                    </h5>
                    <p
                        class="registration__panel_subtitle text-left mt-3"
                        >Вы можете скачать файлы на свой компьютер
                    </p>
                    <ul class="panel-list">
                        <li
                            v-for="link in panelContent"
                            :key="link.text"
                            class="panel-list_item">
                            <a
                                :href="link.link"
                                target="_blank"
                                >{{link.text}}
                            </a>
                        </li>
                    </ul>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
export default {
    name: "PanelList",
    props: {

    },
    data() {
        return {
            panelContent: [
                {
                    text: 'Согласие на обработку персональных данных и получение кредитного отчета',
                    link: 'https://paylate.ru/wp-content/uploads/2024/03/template-of-individual-terms-and-conditions-application-offer-consents-creditline.pdf'
                },
                {
                    text: 'Договор (общие условия) о предоставлении займа',
                    link: 'https://paylate.ru/wp-content/uploads/2024/09/general-terms-and-conditions-of-the-loan-agreement-creditline.pdf'
                },
                {
                    text: 'Правила предоставления займов',
                    link: 'https://paylate.ru/wp-content/uploads/2024/03/rules-for-granting-loans-creditline.pdf'
                },
                {
                    text: 'Соглашение об использовании ПЭП',
                    link: 'https://paylate.ru/wp-content/uploads/2024/03/agreement-on-the-use-of-a-simple-electronic-signature-creditline.pdf'
                },
            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vtb-variables.scss";
@import "@/assets/scss/_travelata-variables.scss";

.registration__panel {
    margin-top: 20px;
        .v-expansion-panel-header {
            margin-top: 0 !important;
            min-height: 0 !important;
            border: none;
            box-shadow: none;
        }
    &_container {
        background: #fcfcfc;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        padding: 24px;
        margin-top: 18px;
        text-align: left;

        & .panel-list {
            display: grid;
            grid-template-columns: 49% 49%;
            justify-content: space-between;
            padding-left: 0;
            list-style: none;

            &_item {
                padding-left: 36px;
                margin-top: 18px;

                &:before {
                    content: "";
                    position: absolute;
                    width: 22px;
                    height: 17px;
                    left: 0;
                    top: 1px;
                    background-repeat: no-repeat;
                }
            //
                a {
                    text-decoration: none;

                }
            }
        }
    }
    &.cash {
        p {
            margin: 0;
            font-family: "Roboto";
        }
        .registration__panel{
            &_text {
                font-family: Roboto;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.4px;
                color: #848485;
            }
            &_title {
                font-family: Roboto;
                font-weight: 400;
                font-size: 14px;
                line-height: 1.3em;
                color: #0a0a0d;
            }
            &_subtitle {
                font-family: Roboto;
                font-weight: 400;
                font-size: 12px;
                line-height: 1.3em;
                color: #0a0a0d;
            }
        }

        & .panel-list {
            &_item {
                font-family: "Roboto";
                font-weight: 300;
                font-size: 12px;
                line-height: 16px;
                position: relative;

                &:before {
                    background-image: url('~@/assets/img/file-wordlist-item.svg');
                }

                & a {
                    color: #656565;
                }
            }
        }
    }
    &.nordwind {
        .registration__panel{
            &_text {
                font-family: Roboto;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.4px;
                color: #848485;
            }
            &_title {
                font-family: Roboto;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.4px;
                color: #0a0a0d;
            }
            &_subtitle {
                font-family: Roboto;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.4px;
                color: #848485;
            }
        }

        & .panel-list {
            &_item {
                font-family: "Roboto";
                font-weight: 300;
                font-size: 12px;
                line-height: 16px;
                position: relative;

                &:before {
                    background-image: url('~@/assets/img/nordwind/nordwind-word.svg');
                }

                & a {
                    color: #656565;
                }
            }
        }
    }
    &.southwind {
        .registration__panel{
            &_text {
                font-family: Roboto;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.4px;
                color: #848485;
            }
            &_title {
                font-family: Roboto;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.4px;
                color: #0a0a0d;
            }
            &_subtitle {
                font-family: Roboto;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.4px;
                color: #848485;
            }
        }

        & .panel-list {
            &_item {
                font-family: "Roboto";
                font-weight: 300;
                font-size: 12px;
                line-height: 16px;
                position: relative;

                &:before {
                    background-image: url('~@/assets/img/nordwind/nordwind-word.svg');
                }

                & a {
                    color: #656565;
                }
            }
        }
    }
    &.vtb {
        .registration__panel{
            &_text {
                font-family: VtbRegular;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.4px;
                color: #848485;
            }
            &_title {
                font-family: VtbRegular;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.4px;
                color: #0a0a0d;
            }
            &_subtitle {
                font-family: VtbRegular;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.4px;
                color: #848485;
            }
        }

        & .panel-list {
            &_item {
                font-family: VtbLight;
                font-weight: 300;
                font-size: 12px;
                line-height: 16px;
                position: relative;

                &:before {
                    background-image: url('~@/assets/img/vtb/vtb_file-word.svg');
                }

                & a {
                    color: #848485;
                }
            }
        }
    }
    &.travelata {
        .registration__panel{
            &_text {
                font-family: 'OpenSansR';
                @include tr-text(12px, 400, 16px);
                color: #707070;
                & > a {
                    color: #059BCE;
                }
            }
            &_title {
                @include tr-text();
                font-family: 'OpenSansR';
                color: #333;
            }
            &_subtitle {
                @include tr-text(12px, 400, 16px);
                font-family: 'OpenSansR';
                color: #707070;
            }
        }

        & .panel-list {
            &_item {
                @include tr-text(12px, 400, 16px);
                font-family: 'OpenSansR';
                color: #707070;
                position: relative;

                &:before {
                    background-image: url('~@/assets/img/travelata/travelata_file-word.svg');
                }

                & a {
                    color: #848485;
                }
            }
        }
    }
}

</style>
