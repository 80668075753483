<template>
    <v-container>
        <slot name="top">
            <div v-if="$root.appType('vtb')" class="container_info_limit">
                <p
                    class="vtb-info-limit_description"
                    >{{ nameSlider.name }}
                </p>
                <div class="container-info-limit_price">
                    <p
                        class="vtb-value-period"
                        >{{ nameSlider.useModel ? sliderSum : sliderArray[sliderValue - 1] }}
                        <span
                            class="ruble-icon"
                            v-html="nameSlider.unit"
                        ></span>
                    </p>
                </div>
            </div>

            <div v-if="$root.appType('cash') || $root.appType('nordwind') || $root.appType('southwind') " class="container_info_limit">
                <p
                    class="container-info-limit_description"
                    >{{ nameSlider.name }}
                </p>
                <div class="container-info-limit_price">
                    <p
                        class="skip-value-period"
                        >{{ nameSlider.useModel ? sliderSum : sliderArray[sliderValue - 1] }}
                        <span
                            class="ruble-icon"
                            v-html="nameSlider.unit"
                        ></span>
                    </p>
                </div>
            </div>

            <div v-if="$root.appType('travelata')" class="container_info_limit">
                <p
                    class="travelata-info-limit_description"
                    >{{ nameSlider.name }}
                </p>
                <div class="travelate_container-info-limit_price">
                    <p
                        class="travelata-value-period"
                        >{{ nameSlider.useModel ? sliderSum : sliderArray[sliderValue - 1] }}
                        <span
                            v-html="nameSlider.unit"
                            class="travelata_ruble-icon"
                        ></span>
                    </p>
                </div>
            </div>
        </slot>

        <div @click="metricHandler" >
          <v-row class="text-center">
            <v-col>
              <v-slider
                v-model="sliderValue"
                :min="min"
                :max="max"
                :step="step"
                ticks
                :color="sliderColors.bg"
                :track-color="sliderColors.track"
                :tick-size="tickSize"
                :tick-labels="tickLabels">
              </v-slider>
              <slot name="labelSliderSumm"></slot>
            </v-col>
          </v-row>
        </div>
    </v-container>
</template>

<script>
import { moneyFormatWithSpace } from "@/components/helpers/MoneyFormat.js";

export default {
    name: "Slider",
    props: {
        nameSlider: Object,
        value: Number,
        min: Number,
        max: Number,
        step: Number,
        comment: String,
        tickSize: Number,
        tickLabels: Array,
        sliderArray: Array,
        sliderColors: Object,
        metricGoalName: String
    },

    data(){
        return {
          isMetricUse: false
        }
    },
    computed: {
        sliderValue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val);
            }
        },

        sliderSum() {
            return moneyFormatWithSpace(this.sliderValue)
        },

        formatCurrency(){
            return this.nameSlider.unit
        }

    },

  methods: {
    metricHandler(){
      if(this.metricGoalName !== undefined && !this.isMetricUse){
          this.$root[`metrika${this.$root.state.addYandexMetricGoals()}`].reachGoal("slider_term");
          this.isMetricUse = true
      }else {
        return
      }
    },

  },


}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vtb-variables.scss";
@import "@/assets/scss/_travelata-variables.scss";

.vtb {
  &-info-limit_description {
    color: #2F3441;
    @include medium-text-thin;
    text-align: initial;
    width: 50%;
  }

  &-value-period {
    @include bold-text;
    color: #2F3441;
    display: flex;
    align-items: center;
    text-align: right;
    margin: 0;
    @media (max-width: 400px) {
      font-size: 30px;
    }

    &>span {
      display: flex;
      align-items: center;
      text-align: right;

      padding: 0 0 0 5px;

      &.ruble-icon {
        @include bold-text;
        color: #2F3441;
        // font-weight: 900;
        transform: translateY(6.6%);
        @media (max-width: 400px) {
          font-size: 30px;
        }
      }
  }
  }
}

.travelata {
    &-info-limit_description {
        @include tr-text(18px, 400);
        color: #333;
        text-align: initial;
        width: 50%;
        font-family: 'OpenSansR';
    }

    &-value-period {
        @include tr-text(32px, 800);
        font-family: 'OpenSansEB';
        color: #333;
        display: flex;
        align-items: center;
        text-align: right;
        margin: 0;
        @media (max-width: 400px) {
            font-size: 30px;
        }

        &>span {


            display: flex;
            align-items: center;
            text-align: right;

            padding: 0 0 0 5px;

            //&_ruble-icon {
            //    @include bold-text;
            //    color: #2F3441;
            //    // font-weight: 900;
            //    transform: translateY(6.6%);
            //    @media (max-width: 400px) {
            //        font-size: 30px;
            //    }
            //
            //}
        }
    }
    &_container-info-limit_price {

    }
    &_ruble-icon {
        @include tr-text(28px, 800);
        font-family: 'Roboto';
        color: #333;
    }
}

</style>
