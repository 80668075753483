<template>
    <div>
        <div class="main-option-for-app text-left">
            <h1 class="forms_title">Заявка отклонена</h1>

            <p class="mt-3 red--text">К сожалению, вам отказано в предоставлении сервиса</p>

<!--            <p class="status-img mt-8">-->
<!--                <img src="@/assets/img/bankiros/sad.png">-->
<!--            </p>-->

            <div>
                <p class="grey--text m-0">
                    Но вы можете получить деньги у наших партнёров.
                </p>
                <p class="">
                    Уровень одобрения по вашей заявке будет составлять
                    <span class="red--text">96%</span>
                </p>

            </div>

            <MainButton>
                Одобренные предложения
            </MainButton>
        </div>
    </div>
</template>

<script>

import MainButton from "@/components/global/MainButton.vue";

export default {
    name: "SouthwindDeclined",
    components: {MainButton},
    computed: {},

    mounted() {
        // this.$root[`metrika${this.$root.state.addYandexMetricGoals()}`].reachGoal("reject");
        this.$root.mindbox("Получил отказ по заявке");
    }
}
</script>

<style lang="scss" scoped>

</style>
