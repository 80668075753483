import { render, staticRenderFns } from "./TemplateSlider.vue?vue&type=template&id=7c1aa687"
import script from "./TemplateSlider.vue?vue&type=script&lang=js"
export * from "./TemplateSlider.vue?vue&type=script&lang=js"
import style0 from "./TemplateSlider.vue?vue&type=style&index=0&id=7c1aa687&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports